import firebase from 'firebase/app'
import 'firebase/auth'

// The configuration below is not sensitive data. You can serenely add your config here
const firebaseConfig = {
  apiKey: "AIzaSyDC5q60Hjhdi5aKkCjPy8YHy1ROivGV8PQ",
  authDomain: "cloud-functions-239000.firebaseapp.com",
  databaseURL: "https://cloud-functions-239000.firebaseio.com",
  projectId: "cloud-functions-239000",
  storageBucket: "cloud-functions-239000.appspot.com",
  messagingSenderId: "662190032169",
  appId: "1:662190032169:web:428cfcee95e49628"
};

firebase.initializeApp(firebaseConfig)



